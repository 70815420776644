// import $ from "jquery";
import gsap from "gsap";

class ourPortfolio {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;

    if (document.querySelector(".js-portfolio-animations")) {
      this.init();
    }
  }

  init() {
    // Initialize Scrollmagic
    const StoryController = new ScrollMagic.Controller();

    $(".gsap-timeline").each(function () {
      const portfolioTimeline = new gsap.timeline({ paused: true }),
        portfolioHeading = this.querySelector(".our-portfolio__heading"),
        portfolioText = this.querySelectorAll(".gsap-timeline-stag"),
        portfolioOverlay = this.querySelector(".overlay");

      portfolioTimeline
        .from(portfolioHeading, 0.8, {
          y: -50,
          autoAlpha: 0,
          ease: "power1.inOut",
        })
        .from(
          portfolioText,
          0.8,
          {
            y: 20,
            delay: 0.3,
            stagger: 0.2,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.4"
        )
        .fromTo(
          portfolioOverlay,
          0.8,
          { skewX: 15, scale: 1.5, xPercent: 0 },
          {
            skewX: 0,
            xPercent: 100,
            transformOrigin: "0% 100%",
            ease: "power4.Out",
          },
          "-=0.4"
        );

      portfolioTimeline.pause();

      // Scroll magic init for banner
      const journey = new ScrollMagic.Scene({
        triggerElement: this,
        offset: 0,
        triggerHook: 0.9,
      })
        .on("start", function () {
          portfolioTimeline.play();
        })
        .addTo(StoryController);
    });
  }
}
export default ourPortfolio;
