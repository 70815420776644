// import $ from "jquery";
import gsap from 'gsap';

class Teamanimation {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;

    if (document.querySelector(".js-team-animate")) {
      this.init();
    }
  }

  init() {
    
    // Initialize Scrollmagic
    const StoryController = new ScrollMagic.Controller();

    // Heading animation start
    const teamtimeline= new gsap.timeline(); 
    const teamheading = document.querySelector(".team-head");
    const teamdesc = document.querySelector(".team-des");
    const teamcard = document.querySelectorAll(".staff-list li");
   
    teamtimeline.from(teamheading, 1,
    {
      y: 20,
      autoAlpha: 0,
      dealy: .4,
      ease: "power1.inOut"
    })
    teamtimeline.from(teamdesc, .8,{
      y: 20,
      dealy: .3,
      autoAlpha: 0,
      ease: "power1.In"
    }, "-=.6");

    teamtimeline.from(teamcard, .5,{
      stagger: .1,
      opacity: 0,
      scale: 1.15,
      rotation: -2,
      transformOrigin: 'top',
      autoAlpha: 0,
      ease: "power1.InOut"
    }, "-=.6");

    // Sectionheading secene
    teamtimeline.pause();

    // Scroll magic init for heading animations
    const teamAnimate = new ScrollMagic.Scene({
        triggerElement: ".team-animate",
        triggerHook: 0.6,
        reverse: true,
    //   triggerHook: "onLeave"
    })
      .on("start", function() {
        teamtimeline.play();
      })
      .addTo(StoryController)
      // .addIndicators({
      //   name: "hello"
      // });
  }

  setOffset() {
    let windowWidth = window.innerWidth;

    if (windowWidth >= 320 && windowWidth < 410) {
      this.offset = 200;
    } else if (windowWidth >= 410 && windowWidth < 767) {
      this.offset = 330;
    } else if (windowWidth >= 767 && windowWidth < 1200) {
      this.offset = 50;
    }
  }

}
export default Teamanimation;