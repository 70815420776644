// import $ from "jquery";
import gsap from "gsap";

class porfolioAnimation {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;

    if (document.querySelector(".js-portfolio-animations1")) {
      this.init();
    }
  }

  init() {
    // Initialize Scrollmagic
    const StoryController = new ScrollMagic.Controller();

    const ourportTimeline = new gsap.timeline({ paused: true });
    ourportTimeline
      .from(
        ".text-pattern",
        1.2,
        {
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "+=.6"
      )
      .from(
        ".cd-banner-heading",
        0.8,
        {
          x: -10,
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "-=.6"
      );

    ourportTimeline.pause();

    // Scroll magic init for banner
    const ourProjects = new ScrollMagic.Scene({
      triggerElement: this,
      offset: 0,
      triggerHook: 0.9,
    })
      .on("start", function () {
        ourportTimeline.play();
      })
      .addTo(StoryController);

    // Blog Interaction start
    $(".portfolio-card-block__list").each(function () {
      const Hometimeline = new gsap.timeline({ paused: true }),
        HmImg = this.querySelectorAll(".portfolio-card-block__list img"),
        HmDate = this.querySelectorAll(".portfolio-content-info");
      Hometimeline.from(
        HmImg,
        0.8,
        {
          y: 20,
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "+=.8"
      ).from(
        HmDate,
        0.8,
        {
          y: 20,
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "+=.9"
      );

      Hometimeline.pause();

      // Scroll magic init for banner
      const hometimeline = new ScrollMagic.Scene({
        triggerElement: this,
        offset: 0,
        triggerHook: 0.9,
      })
        .on("start", function () {
          Hometimeline.play();
        })
        .addTo(StoryController);
    });

    //Scene2

    $(".section-animate").each(function () {
      const sectiontimeline = new gsap.timeline();
      const Sectionheading = document.querySelectorAll(".sec-gsap-time");

      sectiontimeline
        .from(Sectionheading, 0.8, {
          y: 40,
          autoAlpha: 0,
          stagger: 0.3,
          ease: "power4.inOut",
        })
        .from(
          sectionPattern,
          0.8,
          {
            // x: -10,
            dealy: 0.1,
            autoAlpha: 0,
            ease: "power1.In",
          },
          "-=1"
        )
        .from(
          rectangleImg,
          0.8,
          {
            autoAlpha: 0,
            x: 10,
            dealy: 0.1,
            // scale: 1.05,
            ease: "power1.In",
          },
          "-=1"
        );

      // Sectionheading secene
      sectiontimeline.pause();

      // Scroll magic init for heading animations
      const sectionAnimate = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: "onLeave",
        offset: -100,
        // offset: this.offset
      })

        .on("start", function () {
          sectiontimeline.play();
        })
        .addTo(StoryController);
    });
  }
}
export default porfolioAnimation;
