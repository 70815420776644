import gsap from "gsap";
import $ from "jquery";

class homeAnimation {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;

    if (document.querySelector(".js-home-animations")) {
      this.init();
    }
  }

  init() {
    // Header animation
    $(window).on("load", function () {
      gsap.to($(".page-loader"), 0.5, {
        ease: "power4.out",
        opacity: 0,
        onComplete: function () {
          gsap.from($("header .header-brand-logo"), 0.8, {
            ease: "power1.Out",
            opacity: 0,
            y: 15,
          });
          gsap.from($(".mb-header-nav > li"), 0.8, {
            ease: "power1.Out",
            delay: 0.3,
            opacity: 0,
            y: 15,
            stagger: 0.2,
          });

          gsap.from(
            $(".verticle-grid span"),
            0.8,
            {
              x: -20,
              stagger: 0.02,
              autoAlpha: 0,
              ease: "power1.inOut",
            },
            "-=.6"
          );
          gsap.from(
            $(".cs-main-title"),
            0.8,
            {
              y: 20,
              dealy: 0.3,
              autoAlpha: 0,
              ease: "power1.In",
            },
            "-=.8"
          );

          gsap.from(
            $(".banner-heading__text .hide-text"),
            0.8,
            {
              y: 90,
              dealy: 0.4,
              stagger: 0.3,
              autoAlpha: 0,
              ease: "power1.In",
            },
            "-=1.8"
          );
          gsap.from(
            $(".scroll-animate"),
            0.8,
            {
              y: -10,
              autoAlpha: 0,
              ease: "power1.In",
            },
            0.8
          );
        },
      });
    });

    // Initialize Scrollmagic
    const StoryController = new ScrollMagic.Controller();
    // Banner Interaction start
    // const Bannertimeline = new gsap.timeline();
    // Bannertimeline.from(
    //   ".verticle-grid span",
    //   1.2,
    //   {
    //     x: -20,
    //     stagger: 0.02,
    //     autoAlpha: 0,
    //     ease: "power1.inOut",
    //   },
    //   "+=1"
    // )
    //   .from(
    //     ".cs-main-title",
    //     0.8,
    //     {
    //       y: 20,
    //       dealy: 0.3,
    //       autoAlpha: 0,
    //       ease: "power1.In",
    //     },
    //     "-=.8"
    //   )
    //   .from(
    //     ".banner-heading__text .hide-text",
    //     0.8,
    //     {
    //       y: 90,
    //       dealy: 0.4,
    //       stagger: 0.3,
    //       autoAlpha: 0,
    //       ease: "power1.In",
    //     },
    //     "-=.6"
    //   )
    //   .from(
    //     ".scroll-animate",
    //     1,
    //     {
    //       y: -10,
    //       autoAlpha: 0,
    //       ease: "power1.In",
    //     },
    //     "-=.6"
    //   );

    // Scroll magic init for banner
    const homescroll = new ScrollMagic.Scene({
      triggerElement: ".code-banner",
      offset: 0,
      triggerHook: 0.9,
    }).addTo(StoryController);
    // .addIndicators({
    //   name: "banner"
    // });

    // Heading animation start
    $(".section-animate").each(function () {
      const sectiontimeline = new gsap.timeline();
      const Sectionheading = document.querySelectorAll(".sec-gsap-time");
      const sectionPattern = document.querySelector(".code-tribe-pattern");
      const rectangleImg = document.querySelector(".cdsa-video-view");

      sectiontimeline
        .from(Sectionheading, 0.8, {
          y: 40,
          autoAlpha: 0,
          stagger: 0.3,
          ease: "power4.inOut",
        })
        .from(
          sectionPattern,
          0.8,
          {
            // x: -10,
            dealy: 0.1,
            autoAlpha: 0,
            ease: "power1.In",
          },
          "-=1"
        )
        .from(
          rectangleImg,
          0.8,
          {
            autoAlpha: 0,
            x: 10,
            dealy: 0.1,
            // scale: 1.05,
            ease: "power1.In",
          },
          "-=1"
        );

      // Sectionheading secene
      sectiontimeline.pause();

      // Scroll magic init for heading animations
      const sectionAnimate = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: "onLeave",
        offset: -100,
        // offset: this.offset
      })

        .on("start", function () {
          sectiontimeline.play();
        })
        .addTo(StoryController);
      // .addIndicators({
      //   name: "hello"
      // });
    });

    // Our-capabilites scenes

    const ourCapabilities = new gsap.timeline();
    const Sectionheading = document.querySelector(".gs-head-animate");
    const Sectiondesc = document.querySelectorAll(
      ".tech-list .tech-list__item"
    );

    ourCapabilities.from(Sectionheading, 0.6, {
      x: -20,
      autoAlpha: 0,
      ease: "power1.InOut",
    });
    ourCapabilities.from(
      Sectiondesc,
      0.6,
      {
        y: 40,
        stagger: 0.3,
        autoAlpha: 0,
        ease: "power4.In",
      },
      "-=.4"
    );

    // Sectionheading secene
    ourCapabilities.pause();

    // Scroll magic init for heading animations
    const capabilitiesAnimate = new ScrollMagic.Scene({
      triggerElement: ".js-capabilites-animate",
      triggerHook: "onLeave",
      offset: -100,
    })

      .on("start", function () {
        ourCapabilities.play();
      })
      .addTo(StoryController);
    // .addIndicators({
    //   name: "hello"
    // });

    // function parallax() {
    //   var parallaxController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}});
    //   $('.parallax').each(function() {
    //     var trig = this.parentNode,
    //         parallax = this.getAttribute('data-parallax'),
    //         speed = parallax * 100 + '%';

    //     new ScrollMagic.Scene({triggerElement: trig})
    //       .setTween(this, {y: speed})
    //       .addTo(parallaxController);
    //   })
    // }
    // parallax();
  }
}
export default homeAnimation;
