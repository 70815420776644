/**
 * Stylesheets
 */
// import "../../node_modules/swiper/css/swiper.css";
import "../scss/main.scss";

/**
 * Pug Templates
 * Comment these when you are making a production build
 * Comment these when you are making a production build
 * Comment these when you are making a production build
 */
import "../pug/index.pug";
import "../pug/portfolio.pug";

/**
 * Polyfills
 */

import "@babel/polyfill";

/**
 * Scripts
 */

// using ES6 modules

// import $ from 'jquery';

console.log("Codeseas!"); // eslint-disable-line no-console

/** Home-interactions **/
import homeAnimation from "./components/gsap/home-interactions";
const homeinteractions = new homeAnimation();

import ourPortfolio from "./components/gsap/our-portfolio";
const ourportfolio = new ourPortfolio();

/** Team-animate **/
import Teamanimation from "./components/gsap/team-animate";
const teamanimation = new Teamanimation();

/** footer-animations **/

import FooterAnim from "./components/gsap/footer.js";
const footeranimate = new FooterAnim();

/** Po-animations **/

import porfolioAnimation from "./components/portfolios.js";
const Portfolioanimate = new porfolioAnimation();

//For Live Projects
window.addEventListener("load", function () {
  document.querySelector("body").classList.add("loaded");
});

/**
 * END: Page Loader
 */

/**
 * START: Header On Scroll up/down
 */
var lastKnownScrollY = 0;
var currentScrollY = 0;
var ticking = false;
var idOfHeader = "header";
var eleHeader = null;

const classes = {
  pinned: "header-pin",
  unpinned: "header-unpin",
  bgBlack: "bg-black",
};

function onScroll() {
  currentScrollY = window.pageYOffset;
  requestTick();
}

function requestTick() {
  if (!ticking) {
    requestAnimationFrame(update);
  }
  ticking = true;
}

function update() {
  if (currentScrollY < lastKnownScrollY) {
    pin();
  } else if (currentScrollY > lastKnownScrollY) {
    unpin();
  }
  lastKnownScrollY = currentScrollY;
  ticking = false;
}

function pin() {
  if (eleHeader.classList.contains(classes.unpinned)) {
    eleHeader.classList.remove(classes.unpinned);
    eleHeader.classList.add(classes.pinned);
    eleHeader.classList.add(classes.bgBlack);
  }
}

function unpin() {
  if (
    eleHeader.classList.contains(classes.pinned) ||
    eleHeader.classList.contains(classes.bgBlack) ||
    !eleHeader.classList.contains(classes.unpinned)
  ) {
    eleHeader.classList.remove(classes.pinned);
    eleHeader.classList.remove(classes.bgBlack);
    eleHeader.classList.add(classes.unpinned);
  }
}

window.onload = function () {
  document.querySelector(".codeSeaLoader").style.display = "none";
  eleHeader = document.getElementById(idOfHeader);
  document.addEventListener("scroll", onScroll, false);
};

window.onscroll = function () {
  let body = document.body;
  let doc = document.documentElement;
  doc = doc.clientHeight ? doc : body;
  let distance = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  if (distance === 0) {
    // console.log("doc.scrollTop == 0");
    eleHeader.classList.remove(classes.bgBlack);
  } else {
    // console.log("doc.scrollTop != 0");
  }
};

/**
 * END: Header On Scroll up/down
 */

/**
 * START: Header - id - href - Smooth Scroll
 */
document
  // .querySelectorAll('.mb-header-nav li a[href^="#"]')
  .querySelectorAll('a[href^="#"]')
  .forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
      var elems = document.querySelectorAll(
        ".mb-nav-wrap.expanded, .nav-toggle.expanded,.menuBox1"
      );

      [].forEach.call(elems, function (el) {
        el.classList.remove("expanded");
        el.classList.remove("menuBox1");
      });
    });
  });

/**
 * END: Header - id - href - Smooth Scroll
 */

/**
 * START: Header Menu - Hamburger - Mobile Only
 */
// window.onload = function () {
(function () {
  var hamburger = {
    navToggle: document.querySelector(".nav-toggle"),
    nav: document.querySelector("nav"),
    menuBox: document.getElementsByTagName("BODY")[0],
    doToggle: function (e) {
      e.preventDefault();
      this.navToggle.classList.toggle("expanded");
      this.nav.classList.toggle("expanded");
      this.menuBox.classList.toggle("menuBox1");
    },
  };

  hamburger.navToggle.addEventListener("click", function (e) {
    hamburger.doToggle(e);
  });
})();
// };
/**
 * END: Header Menu - Hamburger - Mobile Only
 */

/**
 * END: Portfolio Header
 */

import $ from "jquery";

// Extra

// https://codepen.io/JTParrett/pen/BkDie
// jQuery different scroll speeds

$.fn.moveIt = function () {
  var $window = $(window);
  var instances = [];

  $(this).each(function () {
    instances.push(new moveItItem($(this)));
  });

  window.onscroll = function () {
    var scrollTop = $window.scrollTop();
    instances.forEach(function (inst) {
      inst.update(scrollTop);
    });
  };
};

var moveItItem = function (el) {
  this.el = $(el);
  this.speed = parseInt(this.el.attr("data-scroll-speed"));
};

moveItItem.prototype.update = function (scrollTop) {
  this.el.css("transform", "translateY(" + -(scrollTop / this.speed) + "px)");
};

// Initialization
$(function () {
  $("[data-scroll-speed]").moveIt();
});

/**
 * END: Parallax
 */
