/*eslint-disable*/
import $ from "jquery";
import gsap from 'gsap';

class FooterAnim {
  constructor() {
      if (document.querySelector(".footer-bg-animate")) {
        this.init();
      }
  }

  init() {
    var scrollMagicControllerfooter = new ScrollMagic.Controller();

    $(".footer-bg-animate").each(function() {
      var t4 = new gsap.timeline({}),
        footerExtend = $(this).find(".footer-bg")
      t4.fromTo(
        footerExtend,1,{
          yPercent: 100,
          transformOrigin: "100% 0%",
          autoAlpha: 0,
          ease: "power1.InOut",
        },
        { yPercent: 0, autoAlpha: 1, y: 0, ease: "power1.InOut"})

      t4.from(".footer-animate", .6,{
        y: -20,
        autoAlpha: 0,
        ease: "power4.InOut"
      }, "-=.2");

      t4.from(".footer-logo", .6,{
        y: -20,
        autoAlpha: 0,
        ease: "power4.InOut"
      });

      t4.from(".footer-contact-info", .6,{
        y: -20,
        autoAlpha: 0,
        ease: "power4.InOut"
      }, "-=.4");

      t4.from(".footer-social-list li", .8,{
        y: -20,
        delay: 0.4,
        stagger: .2,
        autoAlpha: 0,
        ease: "power4.InOut"
      }, "-=.6");

      t4.pause();

      /** scroll scenes */
      var bgAnimationfooter = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.6,
        reverse: true,
      })
      .on("start", function() {
        t4.play();
      })
        .addTo(scrollMagicControllerfooter);
    });
    
  }
}

export default FooterAnim;